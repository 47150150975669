import React, {useState, useEffect, useRef } from "react";


function Testimonials(props) {

    const ref = useRef();
const onScreen = useOnScreen(ref); 


function useOnScreen(ref) {

    const [isIntersecting, setIntersecting] = useState(false)
  
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting)
    )
  
    useEffect(() => {
      observer.observe(ref.current)
      return () => { observer.disconnect() }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
  
    return isIntersecting
  }

    return (
       
        <div className="testimonial-container"> 
        <h2>Testimonials</h2>
                {props.data ? 
                props.data.map((d,i) => (
           
                <div ref={ref} key={d.title} className="testimonial">
                    <div className={`${onScreen ? "animate__animated animate__bounceInLeft" : ""}`}>
                    <div className="testimonial-image">
                        <img src={d.imageurl} alt={d.imagealt} />
                    </div>
                    <div className="testimonial-content">
                        <h3>
                            {d.title} 
                        </h3>
                        <p>
                            {d.content}
                        </p>
                        <p className="author">
                            {d.name}
                        </p>
                    </div>
                </div>
            </div>
             )
                )
             : 'loading'}
        </div>
  

    )
}
export default Testimonials;