import React from "react";
import parse from 'html-react-parser';


function ContentLeft(props) {
    return (
    
     <div className="content-left-container">
         
         <div className="image" style={{
         backgroundImage: `url(${props.data.imageurl})`
            }}> 
        </div>


         <div className="text-container">
            <h2>{props.data.title}</h2>
            <p>{parse(props.data.content)}</p>
           
        </div>
         

    </div>
    )
}



export default ContentLeft;  