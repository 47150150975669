import React from "react";
import parse from 'html-react-parser';


function ContentCenter(props) {
    return (
    
     <div className="content-center-container">

         <div className="text-container">
            <h2>{props.data.title}</h2>
            <p>{parse(props.data.content)}</p>
           
        </div>
    </div>
    )
}

export default ContentCenter;  