import React, { useState, useEffect, useRef } from "react";
import fontawesome from "@fortawesome/fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faFileAlt, faFile, faPodcast } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import parse from 'html-react-parser';



fontawesome.library.add(faBook);
fontawesome.library.add(faFileAlt);
fontawesome.library.add(faFile);
fontawesome.library.add(faPodcast);


function Articles(props) {

    const ref = useRef();
    const onScreen = useOnScreen(ref);


    function useOnScreen(ref) {

        const [isIntersecting, setIntersecting] = useState(false)

        const observer = new IntersectionObserver(
            ([entry]) => setIntersecting(entry.isIntersecting)
        )

        useEffect(() => {
            observer.observe(ref.current)
            return () => { observer.disconnect() }
        }, []) // eslint-disable-line react-hooks/exhaustive-deps

        return isIntersecting
    }

    $(".modal-holder .js-close").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(".modal-holder").removeClass("show");
        $(".modal-holder .custom-modal").remove();
    })

    $(".article-container .article:not(.js-close)").click(function () {
        $(".modal-holder .custom-modal").remove();
        $(this).find(".custom-modal").clone().appendTo($(".modal-holder"));
        $(".modal-holder").addClass("show");
    })

    return (
        <div>
            <div className="article-container" id="articles">
                <div className="article-description">
                    <h2>Resources</h2>
                    <p>To support your ongoing learning in becoming the most effective leader you can be, these articles and resources are available for your use.</p>
                    <h3>Articles</h3>
                </div>
                {props.articles ?
                    props.articles.map((d, i) => (

                        <div ref={ref} key={d.title} className="article">
                            <div data-article={i} className={` ${onScreen ? "animate__animated animate__bounceInLeft" : ""}`}>
                                <div className="article-icon">
                                    <FontAwesomeIcon icon={`${d.icon}`} />
                                </div>
                                <div className="article-summary">
                                    <h3>
                                    {parse(d.title)}
                                    </h3>

                                </div>

                                <div id={i} className="custom-modal">
                                    <h2>{parse(d.title)}</h2>
                                    <div className="modal-content">
                                        {parse(d.content)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    )
                    : 'loading'}
            </div>
            <div className="article-container" id="articles">
                <div className="article-description">
                    <h3>Podcasts</h3>
                </div>
                {props.podcasts ?
                    props.podcasts.map((d, i) => (

                        <div ref={ref} key={d.title} className="article">
                            <div data-article={i} className={` ${onScreen ? "animate__animated animate__bounceInLeft" : ""}`}>
                                <div className="article-icon">
                                    <FontAwesomeIcon icon={`${d.icon}`} />
                                </div>
                                <div className="article-summary">
                                    <h3>
                                    {parse(d.title)}
                                    </h3>

                                </div>

                                <div id={i} className="custom-modal">
                                    <h2>{parse(d.title)}</h2>
                                    <div className="modal-content">
                                        {parse(d.content)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    )
                    : 'loading'}

            </div>
            <div className="article-container" id="articles">
                <div className="article-description">
                    <h3>Book Reviews</h3>
                </div>
                {props.books ?
                    props.books.map((d, i) => (

                        <div ref={ref} key={d.title} className="article">
                            <div data-article={i} className={` ${onScreen ? "animate__animated animate__bounceInLeft" : ""}`}>
                                <div className="article-icon">
                                    <FontAwesomeIcon icon={`${d.icon}`} />
                                </div>
                                <div className="article-summary">
                                    <h3>
                                    {parse(d.title)}
                                    </h3>

                                </div>

                                <div id={i} className="custom-modal">
                                    <h2>{parse(d.title)}</h2>
                                    <div className="modal-content">
                                        {parse(d.content)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    )
                    : 'loading'}

            </div>
            <div className="modal-holder">
                <button className="button js-close">Close</button>
            </div>

            </div>
            )
}
            export default Articles;