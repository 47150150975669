import React, { useState } from "react";
import fontawesome from "@fortawesome/fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from '@fortawesome/free-solid-svg-icons';



fontawesome.library.add(faBars);

function Navigation(props) {
    const [navbarOpen, setNavbarOpen] = useState(false);
    const handleToggle = () => {
        setNavbarOpen(!navbarOpen)
    }



    return (
     <nav className="main-navigation"> 
     {props.location === "header" &&
        <div id="site-identification">
            <h1>Becoming Your Best</h1>
        </div>
     }
        <div className="mobile-toggle">
            <button onClick={handleToggle}>
                <span className="sr-only">Toggle Menu</span>
                <FontAwesomeIcon icon="bars" />
            </button>
        </div>
         <ul className={`menuNav ${navbarOpen ? "showMenu" : ""}`}>
             {props.data ? 
             props.data.map((d,i) => (
                <li key={d.name}><a href={d.link}>{d.name}</a></li>
                 )
             )
            : 'loading'}
         </ul>
         </nav>
    )

}

export default Navigation;
