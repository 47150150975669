import React from "react";
import parse from 'html-react-parser';

function ContentRight(props) {
    return (
     <div className="content-right-container">
         
         <div className="text-container">
            <h2>{props.data.title}</h2>
            <p>{parse(props.data.content)}</p>
           
        </div>
         
         <div className="image" style={{
         backgroundImage: `url(${props.data.imageurl})`
            }}> 
        </div>
    </div>
       
    )
}

export default ContentRight;  