import React from "react";
import parse from 'html-react-parser';

function Hero(props) {
    return (
     <div className="hero-container" style={{
         backgroundImage: `url(${props.data.imageurl})`
     }}> 
        
        <div className="hero-text-container">
            <h2>{props.data.title}</h2>
            <div>{parse(props.data.paragraph)}</div>
            {/* <div className="button-container">
                <a className="button" href={`${props.data.ctaUrl}`}>{props.data.cta}</a>
            </div> */}
        </div>
    </div>
    )
}

export default Hero;  