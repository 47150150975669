import React, { useState, useEffect, useRef } from "react";
import './App.scss';
import JsonData from './data/data.json';
import Testimonials from './components/Testimonials';
import Navigation from './components/Navigation';
import Hero from './components/Hero';
import ContentLeft from './components/ContentLeft';
import ContentRight from './components/ContentRight';
import Contact from './components/Contact';
import ContentCenter from './components/ContentCenter';
import Articles from './components/Articles';

import './components/hero.scss';
import './components/navigation.scss';
import './components/content-left.scss';
import './components/content-right.scss';
import './components/contact.scss';
import './components/testimonials.scss';
import 'animate.css/animate.min.css';
import './components/articles.scss';
import './components/content-center.scss';


const App = () => {

  const ref = useRef();
  const onScreen = useOnScreen(ref);

  function useOnScreen(ref) {

    const [isIntersecting, setIntersecting] = useState(false)

    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting)
    )

    useEffect(() => {
      observer.observe(ref.current)
      return () => { observer.disconnect() }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return isIntersecting
  }


  return (
    <div className="App">
      <header >
        <div className="animate__animated animate__bounceInDown">
          <Navigation location="header" data={JsonData.Navigation}></Navigation>
        </div>
      </header>
      <Hero data={JsonData.HeroSection}></Hero>
      <section ref={ref} id="about" >
        <div className="">
          <ContentCenter data={JsonData.About}></ContentCenter>
        </div>
        <div className="third-bg">
        <ContentRight data={JsonData.Becomingthebest}></ContentRight>
        </div>
      </section>
      <section className=""  id="services">
        <ContentCenter data={JsonData.CoachingDescription}></ContentCenter>
      </section>
      <section ref={ref} id="unnamed">
        <div className={`alt-bg ${onScreen ? "animate__animated animate__bounceInLeft" : ""}`}>
          <ContentRight data={JsonData.Coaching2}></ContentRight>
        </div>
        <div className={`${onScreen ? "animate__animated animate__bounceInRight" : ""}`}>
          <ContentLeft data={JsonData.Coaching1}></ContentLeft>
        </div>
      </section>
      <section>
          <ContentCenter data={JsonData.Emotional} ></ContentCenter>
      </section>
      <section className="alt-bg" id="testimonials">
        <Testimonials data={JsonData.Testimonials}></Testimonials>
      </section>
      <section>
        <Articles articles={JsonData.Articles} podcasts={JsonData.Podcasts} books={JsonData.Books}></Articles>
      </section>
      <section className="third-bg" id="contact">
        <Contact></Contact>
        <ContentCenter data={JsonData.FooterText}></ContentCenter>
      </section>
      <footer>
        <Navigation location="footer" data={JsonData.Navigation}></Navigation>
        <div className="copyright">&copy;2023 Becoming Your Best.</div>
      </footer>
    </div>
  );
}





export default App;
